import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { API_BASE_URL } from '../constants';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet';
import NotFound from './NotFound';

// Material UI Components (make sure @mui/material and @mui/icons-material are installed)
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function WebArticle() {
  const { id } = useParams(); // Get the article ID from the URL
  const [article, setArticle] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  /**
   * Create a snippet for meta descriptions (helpful for iMessage previews).
   */
  const createSnippet = (text, length = 150) => {
    if (!text) return '';
    // Remove newlines and limit length
    const cleanText = text.replace(/\n/g, ' ').trim();
    return cleanText.length > length ? `${cleanText.slice(0, length)}...` : cleanText;
  };

  /**
   * Fetch the article data on component mount.
   */
  useEffect(() => {
    const fetchArticle = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_BASE_URL}/sget/articles?article_id=${id}&sign_urls=true`);
        const data = await response.json();

        if (data.success && data.articles.length > 0) {
          setArticle(data.articles[0]);
        } else {
          setErrorMessage('Article not found');
        }
      } catch (err) {
        setErrorMessage('Failed to load the article');
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  // Handle "NotFound" and other error states
  if (loading) {
    return (
      <div style={{ color: 'white', textAlign: 'center', marginTop: '20%' }}>
        Loading...
      </div>
    );
  }

  if (errorMessage === 'Article not found') {
    return <NotFound />;
  }

  if (errorMessage) {
    return (
      <div style={{ color: 'white', textAlign: 'center', marginTop: '20%' }}>
        {errorMessage}
      </div>
    );
  }

  if (!article) {
    return (
      <div style={{ color: 'white', textAlign: 'center', marginTop: '20%' }}>
        No article data available.
      </div>
    );
  }

  const formattedDate = format(new Date(article.date), 'MMMM dd, yyyy');
  const snippet = createSnippet(article.text_body);

  // Copy link handler for Material UI button
  const handleShareLinkCopy = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        alert('Link copied to clipboard');
      })
      .catch(() => {
        alert('Failed to copy the link');
      });
  };

  // More robust detection for .mp4 or .gif files
  const renderMedia = () => {
    if (!article.gcs_location) return null;

    const url = article.gcs_location.toLowerCase();
    const isMp4 = url.includes('.mp4');
    const isGif = url.includes('.gif');

    if (isMp4) {
      return (
        <video
          src={article.gcs_location}
          controls
          style={{
            width: '100%',
            maxWidth: '800px',
            borderRadius: '10px',
          }}
        />
      );
    } else if (isGif) {
      return (
        <img
          src={article.gcs_location}
          alt="GIF content"
          style={{
            width: '100%',
            maxWidth: '800px',
            borderRadius: '10px',
          }}
        />
      );
    }
    return null;
  };

  return (
    <div
      style={{
        backgroundColor: '#9C3336',
        color: 'white',
        fontFamily: 'Arial, sans-serif',
        padding: '20px',
        minHeight: '100vh',
      }}
    >
      {/* Meta tags for iMessage, social media, etc. */}
      <Helmet>
        <title>{article.title}</title>
        <meta name="description" content={snippet} />
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={snippet} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={`${window.location.origin}/logo_no_bg.svg`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={article.title} />
        <meta name="twitter:description" content={snippet} />
        <meta name="twitter:image" content={`${window.location.origin}/logo_no_bg.svg`} />
      </Helmet>

      {/* Logo row (avoid overlap) */}
      <div style={{ textAlign: 'right', marginBottom: '20px' }}>
        <img
          src="/logo_no_bg.svg"
          alt="Lokos AI Logo"
          style={{
            width: '140px',
            opacity: 0.8,
            filter: 'brightness(0) invert(1)',
          }}
        />
      </div>

      <div
        style={{
          maxWidth: '800px',
          margin: '0 auto',
          lineHeight: '1.8',
          fontSize: '1rem',
          padding: '0 20px',
        }}
      >
        {/* Title */}
        <h1 style={{ textAlign: 'center', fontSize: '2.5rem', marginBottom: '10px' }}>
          {article.title}
        </h1>

        {/* Media (video or gif) */}
        <div style={{ textAlign: 'center', margin: '20px 0' }}>
          {renderMedia()}
        </div>

        {/* Author and Date */}
        <p style={{ textAlign: 'center', fontSize: '1.2rem', marginBottom: '40px' }}>
          By {article.author} &bull; {formattedDate}
        </p>

        {/* Markdown Text Body */}
        <ReactMarkdown>{article.text_body}</ReactMarkdown>

        {/* Share Section */}
        <div
          style={{
            marginTop: '40px',
            paddingTop: '20px',
            borderTop: '1px solid rgba(255, 255, 255, 0.2)',
            textAlign: 'center',
          }}
        >
          <p style={{ fontSize: '0.9rem', marginBottom: '10px' }}>Share this article:</p>
          <IconButton
            onClick={handleShareLinkCopy}
            style={{ color: 'white' }}
            aria-label="Copy Share Link"
          >
            <ContentCopyIcon />
            <span style={{ marginLeft: '5px' }}>Copy Share Link</span>
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default WebArticle;
