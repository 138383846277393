import React from 'react';
import { motion } from 'framer-motion';

function NotFound() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        fontFamily: "'Arial', sans-serif",
      }}
    >
      <motion.img
        src="/logo_no_bg.svg"
        alt="Lokos AI Logo"
        style={{ width: '50%', marginBottom: '20px' }}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      />
      <motion.h1
        style={{ fontSize: '2.5rem', color: '#6B5755', marginBottom: '10px' }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 1 }}
      >
        Page Not Found
      </motion.h1>
      <motion.p
        style={{ fontSize: '1.2rem', color: '#9C3336', maxWidth: '600px' }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1, duration: 1 }}
      >
        The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
      </motion.p>
    </div>
  );
}

export default NotFound;
