import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Contact from './components/Contact';
import About from './components/About';
import AdminDashboard from './components/AdminDashboard';
import WebAudioDemo from './components/WebAudioDemo';
import WebArticle from './components/WebArticle';
import FetchPage from './components/FetchPage';
import AnamAIComponent from './components/AnamAIComponent';
import FlightSeatSelector from './components/PlaneSeatMap';
import NotFound from './components/NotFound';

import ReactGA from 'react-ga4';

const TRACKING_ID = 'G-122S2TFVZ5';
ReactGA.initialize(TRACKING_ID); // Initialize GA4

const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  return null;
};

function App() {
  return (
    <Router>
      <RouteTracker />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/experimental_admin" element={<AdminDashboard />} />
        <Route path="/experimental_fetch_page" element={<FetchPage />} />
        <Route path="/experimental_anam_demo" element={<AnamAIComponent />} />
        <Route path="/personal_travel" element={<FlightSeatSelector />} />
        <Route path="/articles/:id" element={<WebArticle />} />
        <Route path="/:username" element={<WebAudioDemo />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
