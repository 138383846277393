import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

function Contact() {
  const particlesInit = async (engine) => {
    await loadFull(engine);
  };

  // Load the Google Calendar button script and CSS
  useEffect(() => {
    // Create link for the scheduling button CSS
    const link = document.createElement('link');
    link.href = 'https://calendar.google.com/calendar/scheduling-button-script.css';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    // Create script for the scheduling button
    const script = document.createElement('script');
    script.src = 'https://calendar.google.com/calendar/scheduling-button-script.js';
    script.async = true;
    script.onload = () => {
      // Once script is loaded, initialize the button
      if (window.calendar && window.calendar.schedulingButton) {
        window.calendar.schedulingButton.load({
          url: 'https://calendar.google.com/calendar/appointments/schedules/AcZssZ0P9MexJg1aqA03VdEXOq0aEqz3Z2dSB1dBqG_Z1FSfbmCP8xsiwLmq5R3HJmkHQfjUmVCDEW_0?gv=true',
          color: '#616161',
          label: 'Book an appointment',
          target: document.getElementById('calendarButton'),
        });
      }
    };
    document.body.appendChild(script);
  }, []);

  return (
    <div
      style={{
        position: 'relative',
        fontFamily: "'Arial', sans-serif",
        color: '#6B5755',
        backgroundColor: '#ffffff',
        overflowX: 'hidden',
      }}
    >
      {/* Navbar */}
      <nav
        style={{
          position: 'fixed',
          top: 0,
          width: '100%',
          backgroundColor: '#ffffff',
          borderBottom: '1px solid #f0f0f0',
          zIndex: 100,
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 0',
        }}
      >
        <div style={{ display: 'flex', gap: '30px' }}>
          <a href="/" style={{ textDecoration: 'none', color: '#6B5755' }}>
            Home
          </a>
          <a href="/about" style={{ textDecoration: 'none', color: '#6B5755' }}>
            About
          </a>
          <a
            href="/contact"
            style={{ textDecoration: 'none', color: '#9C3336', fontWeight: 'bold' }}
          >
            Contact
          </a>
        </div>
      </nav>

      {/* Particles Background */}
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
          background: {
            color: { value: '#ffffff' },
          },
          fpsLimit: 60,
          interactivity: {
            events: {
              onHover: { enable: true, mode: 'bubble' },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 200,
                size: 10,
                duration: 2,
                opacity: 0.8,
                speed: 3,
              },
            },
          },
          particles: {
            color: { value: '#9C3336' },
            links: {
              color: '#6B5755',
              distance: 150,
              enable: true,
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 0.7,
              outModes: { default: 'bounce' },
            },
            number: {
              density: { enable: true, area: 800 },
              value: 60,
            },
            opacity: { value: 0.5 },
            shape: {
              type: 'circle',
            },
            size: {
              value: { min: 2, max: 5 },
            },
          },
          detectRetina: true,
        }}
        style={{
          position: 'absolute',
          zIndex: -1,
          width: '100%',
          height: '100%',
        }}
      />

      {/* Main Content */}
      <div
        style={{
          position: 'relative',
          minHeight: '100vh',
          padding: '0 20px',
          marginTop: '60px',
        }}
      >
        {/* Contact Heading */}
        <section
          style={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <motion.h1
            style={{ fontSize: '3rem', color: '#9C3336', marginBottom: '20px' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            Contact Us
          </motion.h1>
          <motion.p
            style={{ fontSize: '1.2rem', lineHeight: '1.8', marginBottom: '40px' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 1 }}
          >
            We'd love to hear from you! <br /> Call in, email, or directly book an appointment with us.
          </motion.p>
          {/* Contact Details */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 1 }}
            style={{
              padding: '20px',
              borderRadius: '10px',
              background: '#ffffff',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              maxWidth: '400px',
              margin: '0 auto',
              fontFamily: 'Arial, sans-serif',
            }}
          >
            <p style={{ fontSize: '1.2rem', margin: '10px 0', fontWeight: 'bold' }}>
              We're based in Lower Manhattan.
            </p>
            <p style={{ fontSize: '1rem', margin: '10px 0' }}>
              <strong>Text and phone support:</strong>{' '}
              <a
                href="tel:+16469821338"
                style={{ color: 'rgb(156, 51, 54)', textDecoration: 'none' }}
              >
                +1 (646) 982-1338
              </a>
            </p>
            <p style={{ fontSize: '1rem', margin: '10px 0' }}>
              <strong>Email:</strong>{' '}
              <a
                href="mailto:info@lokos.ai"
                style={{ color: 'rgb(156, 51, 54)', textDecoration: 'none' }}
              >
                info@lokos.ai
              </a>
            </p>

            {/* Google Calendar Appointment Button */}
            <div id="calendarButton" style={{ marginTop: '20px' }}></div>
          </motion.div>
        </section>
      </div>
    </div>
  );
}

export default Contact;
