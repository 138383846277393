import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
} from '@mui/material';
import { API_BASE_URL } from '../constants';

function ArticlesManagement() {
  const [articles, setArticles] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [newArticle, setNewArticle] = useState({
    title: '',
    author: '',
    text_body: '',
    prompt: '',
    gcs_location: '',
  });
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const fetchArticles = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/sget/articles`);
      const data = await response.json();
      if (data.success) {
        setArticles(data.articles);
      } else {
        console.error(`Failed to fetch articles: ${data.error}`);
      }
    } catch (error) {
      console.error('Error fetching articles', error);
    }
  };

  useEffect(() => {
    fetchArticles();
  }, []);

  const handleAddArticle = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/sadd_article/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newArticle),
      });
      const data = await response.json();
      if (data.success) {
        // Refresh list and reset form
        fetchArticles();
        setNewArticle({ title: '', author: '', text_body: '', prompt: '', gcs_location: '' });
      } else {
        console.error(`Failed to add article: ${data.error}`);
      }
    } catch (error) {
      console.error('Error adding article', error);
    }
  };

  const handleEditArticle = async () => {
    try {
      // Replace `id` with `article_id` in the selectedArticle object
      const updatedArticle = { ...selectedArticle, article_id: selectedArticle.id };
      delete updatedArticle.id; // Remove the original `id` field

      const response = await fetch(`${API_BASE_URL}/sedit/articles`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedArticle),
      });
      const data = await response.json();
      if (data.success) {
        // Refresh list and clear selection
        fetchArticles();
        setSelectedArticle(null);
      } else {
        console.error(`Failed to edit article: ${data.error}`);
      }
    } catch (error) {
      console.error('Error editing article', error);
    }
  };

  const handleDeleteArticle = async () => {
    if (!selectedArticle || !selectedArticle.id) return;
    try {
      const response = await fetch(`${API_BASE_URL}/sdelete/articles/${selectedArticle.id}`, {
        method: 'GET',
      });
      const data = await response.json();
      if (data.success) {
        // Refresh list and clear selection
        fetchArticles();
        setSelectedArticle(null);
        setConfirmationOpen(false);
      } else {
        console.error(`Failed to delete article: ${data.error}`);
      }
    } catch (error) {
      console.error('Error deleting article', error);
    }
  };

  const handleFieldChange = (e, isEdit = false) => {
    const { name, value } = e.target;
    if (isEdit) {
      setSelectedArticle((prev) => ({ ...prev, [name]: value }));
    } else {
      setNewArticle((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleArticleSelect = (event, newValue) => {
    // If user picks an existing article (with an id) from the list:
    if (newValue && newValue.id) {
      setSelectedArticle(newValue);
      setNewArticle({ title: '', author: '', text_body: '', prompt: '', gcs_location: '' });
    } else {
      // Otherwise, treat this as a new article title
      setSelectedArticle(null);
      if (typeof newValue === 'string') {
        setNewArticle((prev) => ({ ...prev, title: newValue }));
      } else if (newValue && newValue.inputValue) {
        setNewArticle((prev) => ({ ...prev, title: newValue.inputValue }));
      } else if (newValue) {
        setNewArticle((prev) => ({ ...prev, title: newValue.title }));
      } else {
        setNewArticle((prev) => ({ ...prev, title: '' }));
      }
    }
  };

  const isAddButtonDisabled = !(
    newArticle.title.trim() &&
    newArticle.author.trim() &&
    newArticle.text_body.trim()
  );

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', p: 2 }}>
      <Card variant="outlined">
        <CardHeader
          title="Articles Management"
          sx={{ backgroundColor: '#f5f5f5' }}
        />
        <CardContent>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Select an existing article to edit, or type a new title to create a new article.
            </Typography>

            <Autocomplete
              freeSolo
              options={articles}
              value={
                selectedArticle ||
                (newArticle.title ? { title: newArticle.title } : null)
              }
              onChange={handleArticleSelect}
              onInputChange={(event, newInputValue) => {
                // Make sure the typed value always updates the correct article object
                if (selectedArticle) {
                  setSelectedArticle((prev) => ({ ...prev, title: newInputValue }));
                } else {
                  setNewArticle((prev) => ({ ...prev, title: newInputValue }));
                }
              }}
              getOptionLabel={(option) => (option.title ? option.title : '')}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Title"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Box>

          <TextField
            label="Author"
            name="author"
            value={selectedArticle ? selectedArticle.author : newArticle.author}
            onChange={(e) => handleFieldChange(e, !!selectedArticle)}
            fullWidth
            sx={{ mb: 2 }}
          />

          <TextField
            label="Text Body"
            name="text_body"
            value={selectedArticle ? selectedArticle.text_body : newArticle.text_body}
            onChange={(e) => handleFieldChange(e, !!selectedArticle)}
            fullWidth
            multiline
            rows={8}
            sx={{ mb: 2 }}
          />

          <TextField
            label="Prompt"
            name="prompt"
            value={selectedArticle ? selectedArticle.prompt : newArticle.prompt}
            onChange={(e) => handleFieldChange(e, !!selectedArticle)}
            fullWidth
            sx={{ mb: 2 }}
          />

          <TextField
            label="GCS Location"
            name="gcs_location"
            value={selectedArticle ? selectedArticle.gcs_location : newArticle.gcs_location}
            onChange={(e) => handleFieldChange(e, !!selectedArticle)}
            fullWidth
            sx={{ mb: 3 }}
          />

          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={selectedArticle ? handleEditArticle : handleAddArticle}
              disabled={selectedArticle ? false : isAddButtonDisabled}
            >
              {selectedArticle ? 'Edit Article' : 'Add Article'}
            </Button>

            {selectedArticle && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setConfirmationOpen(true)}
              >
                Delete Article
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>

      <Dialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this article?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteArticle} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ArticlesManagement;
